import React, { useState, useEffect } from "react";
import Modal from "components/modal/Modal";
import { useToastContext, ADD } from "context/ToastContext";
import { useHttp } from "hooks"; // Ensure you import useHttp properly
import { updateWarehouse } from "./WarehouseService";
import { useQueryClient } from "@tanstack/react-query";
const UpdateChannelModal = (props) => {
  const { toastDispatch } = useToastContext();
  const [warehouseDetails, setWarehouseDetails] = useState({
    channelId: "", // You might need to capture channelId for channelId
  });
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();

  const updateNewWarehouse = async () => {
    setLoading(true);
    try {
      const loggedInUser = JSON.parse(sessionStorage.getItem("user"));
      let data = {
        ...warehouseDetails,
        userId: loggedInUser?.id,
      };
      const { msg, success } = await updateWarehouse(props.warehouse, data);
      if (success) {
        queryClient.invalidateQueries({
          queryKey: ["warehouses"],
        });
        props.close();
        props.refresh();
        toastDispatch({
          type: ADD,
          payload: {
            content: msg,
            type: "success",
          },
        });
      } else {
        toastDispatch({
          type: ADD,
          payload: {
            content: msg,
            type: "danger",
          },
        });
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setWarehouseDetails({ ...warehouseDetails, [name]: value });
  };
  return (
    <Modal
      close={props.close}
      size="sm"
      onAccept={() => updateNewWarehouse()}
      title="Update Warehouse Channel"
      form="warehouseUpdateForm"
      loading={loading}
    >
      <form onSubmit={(e) => e.preventDefault()} id="warehouseUpdateForm">
        {/* Channel Dropdown */}
        <div className="form-group">
          <label>Select Channel</label>
          <select
            className="form-control form-control-sm"
            onChange={onChange}
            name="channelId" // Assuming "channelId" represents channelId in warehouseDetails
            value={warehouseDetails.channelId}
            required={true}
          >
            <option value="" disabled selected>
              Choose a channel
            </option>
            {props?.channelData?.map((channel) => (
              <option value={channel?._id} key={channel?._id}>
                {channel?.name}
              </option>
            ))}
          </select>
        </div>
      </form>
    </Modal>
  );
};

export default UpdateChannelModal;
