export const assignPartner = async (data, user_id) => {
  let res = await fetch(
    process.env.REACT_APP_BASE_URL + `assign_open_market_partner/${user_id}`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        key: process.env.REACT_APP_API_KEY,
        secret: process.env.REACT_APP_API_SECRET,
        country: JSON.parse(sessionStorage.getItem("user"))?.country,
      },
      body: JSON.stringify(data),
    }
  );
  return await res.json();
};
