import React, { useState } from "react";
import Modal from "components/modal/Modal";
import { useToastContext, ADD } from "context/ToastContext";
import { useHttp } from "hooks";
import { useAssignToTeam } from "./hooks/useAssignToTeam";
import { Select, Form } from "antd";
import { useTeamLeader } from "./hooks/useTeamLeaders";

const ReturnToWIPModel = (props) => {
  const { toastDispatch } = useToastContext();
  const {
    error: errorWarehouses,
    loading: assigningWarehouse,
    sendHttpRequest: ReturnToWip,
  } = useHttp();
  const { teamLeaders, isLoading } = useTeamLeader();
  const [department, setDepartment] = useState("");
  const [reason, setReason] = useState("");
  const [teamLeaderId, setTeamLeaderId] = useState();
  const [remarks, setRemarks] = useState("");
  const { assigningToTeam, isLoading: isAssigningLeas } = useAssignToTeam();

  const handleDepartmentChange = (value) => {
    setDepartment(value);
    setReason(""); // Reset reason when department changes
  };

  const handleReasonChange = (value) => {
    setReason(value);
  };

  const handleTeamLeaderChange = (value) => {
    setTeamLeaderId(value);
  };

  const departmentOptions = [
    { value: "Operations Department", label: "Operations Department" },
    { value: "Logistics Department", label: "Logistics Department" },
    { value: "Repair Department", label: "Repair Department" },
    { value: "Sale Department", label: "Sale Department" },
    { value: "Customer Issue", label: "Customer Issue" },
    { value: "QC department", label: "QC department" },
    { value: "Product issue", label: "Product issue" },
  ];

  const reasonOptions = {
    "Operations Department": [
      {
        value: "Item Stopped working well after Usage",
        label: "Item Stopped working well after Usage",
      },
      {
        value: "Item received broken or defective",
        label: "Item received broken or defective",
      },
      {
        value: "Item received with Missing parts/ Accessories",
        label: "Item received with Missing parts/ Accessories",
      },
    ],
    "Product issue": [
      {
        value: "Docomo software",
        label: "Docomo software",
      },
      {
        value: "Network locked",
        label: "Network locked",
      },
      {
        value: "Routed device",
        label: "Routed device",
      },
    ],
    "Logistics Department": [
      { value: "Package was damaged", label: "Package was damaged" },
      {
        value: "Item received used or expired",
        label: "Item received used or expired",
      },
      {
        value: "Late fullfillment",
        label: "Late fulfilment",
      },
    ],
    "Repair Department": [
      { value: "QC Failed ", label: "QC Failed" },
      {
        value: "Item seems to be fake / Unauthentic",
        label: "Item seems to be fake / Unauthentic",
      },
      { value: "Burnt screen", label: "Burnt screen" },
      { value: "Cracked screen", label: "Cracked screen" },
      { value: "Hard power button", label: "Hard power button" },
      { value: "Hard volume keys", label: "Hard volume keys" },
      { value: "Hard keys", label: "Hard keys" },
      { value: "Dented Frame", label: "Dented Frame" },
      { value: "Scratched frame", label: "Scratched frame" },
      { value: "Scratched backcover", label: "Scratched backcover" },
      { value: "Backcover peeling", label: "Backcover peeling" },
      { value: "Dented backcover", label: "Dented backcover" },
      { value: "Loose backcover", label: "Loose backcover" },
      { value: "Broken backglass", label: "Broken backglass" },
      { value: "Dented body", label: "Dented body" },
      { value: "Missing camera lens", label: "Missing camera lens" },
      { value: "Flickering", label: "Flickering" },
      { value: "Gapping", label: "Gapping" },
      { value: "Non original LCD", label: "Non original LCD" },
      { value: "No fingerprints working", label: "No fingerprints working" },
      { value: "Missing Camera", label: "Missing Camera" },
      { value: "Front camera blurry", label: "Front camera blurry" },
      { value: "Rear camera blurry", label: "Rear camera blurry" },
      { value: "Receiver fail", label: "Receiver fail" },
      { value: "Crackling speaker", label: "Crackling speaker" },
      { value: "Battery failure", label: "Battery failure" },
      { value: "Battery shaking", label: "Battery shaking" },
      { value: "Mkopa unlock failed", label: "Mkopa unlock failed" },
      { value: "Missing backlit", label: "Missing backlit" },
      { value: "Speaker not working", label: "Speaker not working" },
      { value: "Not powering on", label: "Not powering on" },
      { value: "Not charging", label: "Not charging" },
      {
        value: "Not increasing brightness",
        label: "Not increasing brightness",
      },
      { value: "IMEI mismatch", label: "IMEI mismatch" },
      { value: "Samsung account issue", label: "Samsung account issue" },
      { value: "Missing volume button", label: "Missing volume button" },
      { value: "Needs Gum", label: "Needs Gum" },
      { value: "Simtray not fitting", label: "Simtray not fitting" },
      { value: "Touch fail", label: "Touch fail" },
      { value: "Motherboard issue", label: "Motherboard issue" },
      { value: "Dots on frame", label: "Dots on frame" },
      { value: "Dots on backcover", label: "Dots on backcover" },
      { value: "Degumming", label: "Degumming" },
      { value: "Software issue", label: "Software issue" },
      { value: "Network issue", label: "Network issue" },
      { value: "Cracked speaker", label: "Cracked speaker" },
      { value: "Broken simtray", label: "Broken simtray" },
      { value: "No display", label: "No display" },
      { value: "Proximity issue", label: "Proximity issue" },
      { value: "Speaker failure", label: "Speaker failure" },
      { value: "Hard power button", label: "Hard power button" },
      { value: "Liquid damage", label: "Liquid damage" },
      { value: "Network locked", label: "Network locked" },
      { value: "Non-original LCD issue", label: "Non-original LCD issue" },
      { value: "Cracked camera lens", label: "Cracked camera lens" },
    ],
    "Sale Department": [
      {
        value: "Size is incorrect / doesn't fit as expected",
        label: "Size is incorrect / doesn't fit as expected",
      },
      {
        value: "Wrong Item / Colour / Size / Model",
        label: "Wrong Item / Colour / Size / Model",
      },
      {
        value: "Store to store tranfer",
        label: "Store to store tranfer",
      },
    ],
    "Customer Issue": [
      {
        value: "Order cancelled",
        label: "Order cancelled",
      },
      {
        value: "Delivery failed",
        label: "Delivery failed",
      },
    ],
    "QC department": [
      {
        value: "Re-QC - Old device to be rechecked",
        label: "Re-QC - Old device to be rechecked",
      },
      {
        value: "Repacking",
        label: "Repacking",
      },
      {
        value: "Country transfers",
        label: "Country transfers",
      },
    ],
  };
  const handleRemarksChange = (e) => {
    setRemarks(e.target.value);
  };

  const teamLeadersArray =
    teamLeaders?.users?.map((team) => ({
      value: team?._id,
      label: team?.fname + " " + team?.lname,
    })) || [];

  const assign = async () => {
    let loggedInUser = JSON.parse(sessionStorage.getItem("user"));
    let data = {
      devices: props?.selectedRepairRequest,
      userId: loggedInUser?.id,
      flag:"sales_dashboard",
      department,
      reason,
      remarks,
      // teamLeaderId,
    };
    ReturnToWip(`return_to_wip_bulk`, "PUT", data, ({ msg }) => {
      toastDispatch({
        type: ADD,
        payload: {
          content: msg,
          type: "success",
        },
      });
      props.refresh();
      props.close();
    });
  };

  return (
    <Modal
      close={props.close}
      onAccept={() => assign()}
      title="Return to INBOUND"
      form="ReturnToWIPModels"
      size="sm"
      loading={assigningWarehouse}
    >
      <strong>Do you want to return to INBOUND?</strong> <br />
      <br />
      <form onSubmit={(e) => e.preventDefault()} id="ReturnToWIPModels">
        <div style={{ display: "flex", flexDirection: "column" }}>
          {/* <Form.Item
            name="teamLeader"
            label="Select a Team Leader"
            rules={[
              {
                required: true,
                message: "Please select a Team Leader",
              },
            ]}
          >
            <select
              disabled={isLoading}
              placeholder="Select a Team Leader"
              onChange={(e) => handleTeamLeaderChange(e.target.value)}
              className="form-control form-control-sm"
              required={true}
            >
              <option value="" disabled selected>
                Select a Team Leader
              </option>
              {teamLeadersArray.map((team) => (
                <option key={team.value} value={team.value}>
                  {team.label}
                </option>
              ))}
            </select>
          </Form.Item> */}
          <Form.Item
            name="department"
            label="Related Department"
            rules={[
              {
                required: true,
                message: "Please select a department",
              },
            ]}
          >
            <select
              className="form-control form-control-sm"
              placeholder="Select a Department"
              onChange={(e) => handleDepartmentChange(e.target.value)}
              value={department}
              required={true}
            >
              <option value="" disabled selected>
                Select a Department
              </option>
              {departmentOptions.map((dept) => (
                <option key={dept.value} value={dept.value}>
                  {dept.label}
                </option>
              ))}
            </select>
          </Form.Item>
          <Form.Item
            name="reason"
            label="Reason"
            rules={[
              {
                required: true,
                message: "Please select a reason",
              },
            ]}
          >
            <select
              className="form-control form-control-sm"
              placeholder="Select a Reason"
              onChange={(e) => handleReasonChange(e.target.value)}
              value={reason}
              required={true}
              disabled={!department}
            >
              <option value="" disabled selected>
                Select a Reason
              </option>
              {department &&
                reasonOptions[department]?.map((reason) => (
                  <option key={reason.value} value={reason.value}>
                    {reason.label}
                  </option>
                ))}
            </select>
          </Form.Item>
        </div>
        <Form.Item
          name="remarks"
          label="Remarks"
          rules={[
            {
              required: true,
              message: "Please provide remarks",
            },
          ]}
        >
          <textarea
            className="form-control form-control-sm"
            rows={1}
            onChange={handleRemarksChange}
            value={remarks}
            required={true}
          />
        </Form.Item>
      </form>
    </Modal>
  );
};

export default ReturnToWIPModel;
