import React, { useState } from "react";
import Modal from "components/modal/Modal";
import { ADD, useToastContext } from "context/ToastContext";
import { Select } from "antd";
import { useTeamLeader } from "./hooks/useTeamLeaders";
import { useAssignToTeam } from "./hooks/useAssignToTeam";

const filterOption = (input, option) =>
  (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

const AssignToTeamModal = (props) => {
  const { toastDispatch } = useToastContext();
  const { teamLeaders, isLoading } = useTeamLeader();
  const [teamLeaderId, setTeamLeaderId] = useState();

  const { assigningToTeam, isLoading: isAssigningLeas } = useAssignToTeam();

  const onChange = (value) => {
    setTeamLeaderId(value);
  };
  const onSearch = (value) => {
    setTeamLeaderId(value);
  };

  const teamLeadersArrray = teamLeaders?.users?.map((team) => ({
    value: team?._id,
    label: team?.fname + " " + team?.lname,
  }));

  function assignToTeamLead() {
    const data = {
      inventoryId: props.inventoryID,
      teamLeaderId,
      status: props.status,
    };
    assigningToTeam(data, {
      onSuccess: ({ success, msg }) => {
        if (success) {
          props.close();
          props.refresh();
          toastDispatch({
            type: ADD,
            payload: {
              content: msg,
              type: "success",
            },
          });
        } else {
          toastDispatch({
            type: ADD,
            payload: {
              content: msg || "Unexpected Error Occured. Try again!",
              type: "danger",
            },
          });
        }
      },
    });
  }

  return (
    <Modal
      close={props.close}
      onReject={() => {
        props.close();
      }}
      onAccept={() => assignToTeamLead()}
      title={`Push to ${props.status}`}
      form="assignTeamLead"
      loading={isAssigningLeas}
      size="sm"
    >
      <form onSubmit={(e) => e.preventDefault()} id="warehouseForm">
        <div style={{ display: "flex", flexDirection: "column" }}>
          <label>Select and assign a team leader.</label>
          <Select
            disabled={isLoading}
            style={{ width: "50%", height: "3rem" }}
            showSearch
            placeholder="Select a Team Leader"
            optionFilterProp="children"
            onChange={onChange}
            onSearch={onSearch}
            filterOption={filterOption}
            options={teamLeadersArrray}
          />
        </div>
      </form>
    </Modal>
  );
};

export default AssignToTeamModal;
