import React, { useState } from "react";
import Modal from "components/modal/Modal";
import { useToastContext, ADD } from "context/ToastContext";
import { useHttp } from "hooks";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

const BulkDeleteInvModal = (props) => {
  const { toastDispatch } = useToastContext();
  const { error, loading, sendHttpRequest: deleteInvDevice } = useHttp();
  let loggedInUser = JSON.parse(sessionStorage.getItem("user"));
  const [bulkDeleteInfo, setBulkDeleteInfo] = useState({
    password: "",
    email: "",
  });
  const onChange = (e) => {
    const { name, value } = e.target;
    setBulkDeleteInfo({ ...bulkDeleteInfo, [name]: value });
  };
  const RMSchema = Yup.object().shape({
    password: Yup.string().required("Password is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
  });

  const addNewRM = (values) => {
    let data = {
      password: values.password,
      email: values.email,
      devices: props?.selectedRepairRequest,
      userId: loggedInUser?.id,
    };
    deleteInvDevice(`remove_from_inventory_bulk`, "delete", data, ({ msg }) => {
      toastDispatch({
        type: ADD,
        payload: {
          content: msg,
          type: "success",
        },
      });
      props.refresh();
      props.close();
    });
  };
  return (
    <Modal
      close={props.close}
      title="Authorize By Tech Leader (Bulk Delete)"
      form="confirmationBulk"
      size="sm"
      loading={loading}
    >
      <div className="form-group">
        <strong>Are you sure you want to delete these items?</strong>
        <br />
        <Formik
          initialValues={bulkDeleteInfo}
          validationSchema={RMSchema}
          onSubmit={(values) => {
            addNewRM(values);
          }}
        >
          {({ errors, touched, isSubmitting, setFieldValue }) => (
            <Form>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group row">
                    <label className="col-lg-3 col-form-label">Email:</label>
                    <div className="col-lg-9">
                      <Field
                        name="email"
                        type="email"
                        placeholder=""
                        required
                        className={
                          "form-control" +
                          (errors.email && touched.email ? " is-invalid" : "")
                        }
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group row">
                    <label className="col-lg-3 col-form-label">Password:</label>
                    <div className="col-lg-9">
                      <Field
                        name="password"
                        type="password"
                        placeholder=""
                        required
                        className={
                          "form-control" +
                          (errors.password && touched.password
                            ? " is-invalid"
                            : "")
                        }
                      />
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12" style={{ marginTop: "28px" }}>
                <div className="text-right">
                  <button type="submit" className="btn btn-primary legitRipple">
                    Submit <i className="icon-paperplane ml-2" />
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default BulkDeleteInvModal;
