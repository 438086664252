import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AssignRepModal from "./AssignRMModal";
import ModalService from "components/modal/ModalService";
import { Loader } from "components";
import Avatar from "assets/images/avatar.png";
import { useHttp } from "hooks";
import { getRMS } from "./RMService";

const RMBadili = () => {
  const [reps, setReps] = useState([]);
  const [loading, setLoading] = useState(false);
  const [regions, setRegions] = useState([]);
  const {
    error,
    loading: loadingRegions,
    sendHttpRequest: getRegions,
  } = useHttp();

  const getAllReps = async () => {
    setLoading(true);
    try {
      const { msg, success, rms } = await getRMS();
      getRegions("get_regions", "GET", null, ({ data: { regions } }) =>
        setRegions(regions)
      );
      if (success) {
        setReps(rms);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };
  const openModal = (rep) => {
    ModalService.open(AssignRepModal, {
      refresh: () => getAllReps(),
      details: rep,
    });
  };
  useEffect(() => {
    getAllReps();
  }, []);
  return (
    <div>
      {loading && <Loader />}
      {!loading && (
        <div className="card">
          <div className="card-header">
            <div className="header-elements-inline">
              <h4 className="font-weight-semibold mb-1">Badili RMS</h4>
              <div className="header-elements"></div>
            </div>
          </div>

          <div className="card-body">
            {reps?.length === 0 ? (
              <div className="d-flex justify-content-center align-items-center">
                <b>No data available</b>
              </div>
            ) : (
              <div className="row">
                {reps.map((rep) => (
                  <div className="col-xl-3 col-md-6">
                    <div className="card card-body">
                      <div className="media">
                        <div className="mr-3">
                          <img
                            src={Avatar}
                            className="rounded"
                            alt="user "
                            width={55}
                            height={55}
                          />
                        </div>
                        <div className="media-body">
                          <div className="font-weight-semibold">
                            {rep?.fname + " " + rep?.lname}
                          </div>
                          <div className="text-muted">
                            <ul className="list-inline list-inline-dotted text-muted mb-2">
                              <li className="list-inline-item">
                                <a href="#" className="text-muted">
                                  {rep?.region[0]?.name
                                    ? rep?.region[0]?.name
                                    : "-"}
                                </a>
                              </li>
                              {/* <li className="list-inline-item">
                  {rep?.region[0]?.country
                    ? rep?.region[0]?.country?.toUpperCase()
                    : "-"}
                </li> */}
                            </ul>
                            {/* {rep?.email || "Unassigned"}  */}
                          </div>
                          <span className="text-muted">
                            {" "}
                            {rep?.active === true ? (
                              <span className="text-success text-center">
                                Active
                              </span>
                            ) : (
                              "InActive"
                            )}
                          </span>
                        </div>

                        <div className="ml-3 align-self-center">
                          <div className="list-icons">
                            <div className="list-icons-item dropdown">
                              <a
                                href="#"
                                className="list-icons-item dropdown-toggle caret-0"
                                data-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="icon-menu7" />
                              </a>
                              <div
                                className="dropdown-menu dropdown-menu-right"
                                x-placement="bottom-end"
                                style={{
                                  position: "absolute",
                                  willChange: "transform",
                                  top: 0,
                                  left: 0,
                                  transform: "translate3d(16px, 16px, 0px)",
                                }}
                              >
                                {/* <Link
                                  to={`/sales/representative/${rep._id}`}
                                  state={{ agent: rep }}
                                  className="dropdown-item"
                                >
                                  <i className="icon-file-eye" /> Profile
                                </Link> */}

                                <a
                                  href="#"
                                  className="dropdown-item"
                                  onClick={() => openModal(rep)}
                                >
                                  <i className="icon-reading" />
                                  {/* {rep.rms.length < 1
                                    ? "Assign Dealer"
                                    : "Reassign Dealer"} */}
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default RMBadili;
