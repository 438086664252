import React, { useState, useEffect } from "react";
import Modal from "components/modal/Modal";
import { assignPartner, reAssignPartner } from "./RepresentativeService";
import { useToastContext, ADD } from "context/ToastContext";
import { useHttp } from "hooks";

const AssignRepresentativetModal = (props) => {
  const [savingRep, setSavingRep] = useState(false);
  const [assignedPartner, setAssignedPartner] = useState("");
  const [partners, setPartners] = useState([]);
  const { toastDispatch } = useToastContext();
  const { error, loading, sendHttpRequest: getPartners } = useHttp();

  const addNewRep = async () => {
    setSavingRep(true);
    let loggedInUser = JSON.parse(sessionStorage.getItem("user"));
    let data = {
      selectedDealerId: assignedPartner,
      flag: "saleDashboard",
      userId: loggedInUser.id,
      openMarketVenderId: props?.details?._id,
    };
    try {
      const { msg, success } = await assignPartner(data, props?.details?._id);
      if (success) {
        props.refresh();
        props.close();
        toastDispatch({
          type: ADD,
          payload: {
            content: msg,
            type: "success",
          },
        });
      } else {
        toastDispatch({
          type: ADD,
          payload: {
            content: msg,
            type: "danger",
          },
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setSavingRep(false);
    }
  };

  useEffect(() => {
    getPartners("get_dealers", "GET", null, ({ data: { dealers } }) => {
      setPartners(dealers);
    });
  }, []);

  return (
    <Modal
      close={props.close}
      size="sm"
      onAccept={() => addNewRep()}
      title={`${props?.details?.partner?.length < 1 ? "Assign" : "Reassign"} ${
        props?.details?.name
      } to Open Market Vendor`}
      form="representativeForm"
      loading={savingRep}
    >
      {loading && "Preparing...."}
      {!loading && (
        <form onSubmit={(e) => e.preventDefault()} id="representativeForm">
          <div className="row">
            <div className="col-sm-12">
              <div class="form-group">
                <select
                  className="form-control form-control-sm"
                  onChange={(e) => setAssignedPartner(e.target.value)}
                  name="partner"
                  required={true}
                  value={assignedPartner}
                >
                  <option value="" disabled>
                    Select Open Market Vendor
                  </option>
                  {partners?.map((partner) => (
                    <option value={partner?._id} key={partner?._id}>
                      {partner?.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </form>
      )}
    </Modal>
  );
};

export default AssignRepresentativetModal;
