import React from "react";
import Modal from "components/modal/Modal";
import { useToastContext, ADD } from "context/ToastContext";
import { useProcessDevices } from "./hooks/useProcessDevices";

const ProcessWarrantyDevices = (props) => {
  const { toastDispatch } = useToastContext();
  const { processWarreDevice, isLoading } = useProcessDevices();

  return (
    <Modal
      close={props.close}
      onAccept={() =>
        processWarreDevice(props?.id, {
          onSuccess: ({ success, msg }) => {
            toastDispatch({
              type: ADD,
              payload: {
                content: msg,
                type: "success",
              },
            });
            props.close();
          },
        })
      }
      title="Process Warranty"
      form="process_devices"
      size="sm"
      loading={isLoading}
    >
      Would you like to process this device &nbsp;
      <span className="text-danger">{props?.name ?? "unknown"}</span> for
      warranty?
    </Modal>
  );
};

export default ProcessWarrantyDevices;
