import { useMutation, useQueryClient } from "@tanstack/react-query";
import { processWarrentyeDevices } from "../warrentyService";

export function useProcessDevices() {
  const queryClient = useQueryClient();
  const {
    mutate: processWarreDevice,
    isPending: isLoading,
    error,
  } = useMutation({
    mutationFn: processWarrentyeDevices,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["devices"],
      });
    },
  });

  return { processWarreDevice, isLoading, error };
}
