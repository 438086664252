import React, { useEffect, useState } from "react";
import Avatar from "assets/images/avatar.png";
import { useHttp } from "hooks";
import { Loader } from "components";
import AssignRepModal from "./AssignRepresentativeModal";
import ModalService from "components/modal/ModalService";

const OpenMarketPortal = () => {
  const [SalesAgentssData, setSalesAgentssData] = useState([]);
  const { error, loading, sendHttpRequest: getSalesAgents } = useHttp();
  useEffect(() => {
    getSalesAgentsFun();
  }, []);

  const getSalesAgentsFun = () => {
    getSalesAgents(
      "get_open_market_agents",
      "GET",
      null,
      ({ data: { find } }) => {
        let tempVal = [];
        find?.map((val, index) =>
          tempVal?.push({
            _id: val?._id,
            name:
              (val?.fname ? val?.fname : " ") +
              " " +
              (val?.lname ? val?.lname : "-"),
            phone: val?.phone ? val.phone : " ",
            partner: val?.partner,
          })
        );
        setSalesAgentssData(tempVal);
      }
    );
  };
  const openModal = (rep) => {
    ModalService.open(AssignRepModal, {
      refresh: () => getSalesAgentsFun(),
      details: rep,
    });
  };
  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <>
          {!loading && (
            <div className="card">
              <div className="card-header">
                <div className="header-elements-inline">
                  <h4 className="font-weight-semibold mb-1">Open Market</h4>
                  <div className="header-elements"></div>
                </div>
              </div>

              <div className="card-body">
                {SalesAgentssData.length === 0 ? (
                  <div className="d-flex justify-content-center align-items-center">
                    <b>No data available</b>
                  </div>
                ) : (
                  <div className="row">
                    {SalesAgentssData.map((rep) => (
                      <div className="col-xl-3 col-md-6">
                        <div className="card card-body">
                          <div className="media">
                            <div className="mr-3">
                              <img
                                src={Avatar}
                                className="rounded"
                                alt="user "
                                width={55}
                                height={55}
                              />
                            </div>
                            <div className="media-body">
                              <div className="font-weight-semibold">
                                {rep?.name}
                              </div>
                              <div className="text-muted">
                                {rep?.partner && rep.partner.length > 0
                                  ? rep.partner[0].name
                                  : "Unassigned"}
                              </div>
                              <span className="text-muted"> {rep.phone}</span>
                            </div>

                            <div className="ml-3 align-self-center">
                              <div className="list-icons">
                                <div className="list-icons-item dropdown">
                                  <a
                                    href="#"
                                    className="list-icons-item dropdown-toggle caret-0"
                                    data-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <i className="icon-menu7" />{" "}
                                  </a>

                                  <div
                                    className="dropdown-menu dropdown-menu-right"
                                    x-placement="bottom-end"
                                    style={{
                                      position: "absolute",
                                      willChange: "transform",
                                      top: 0,
                                      left: 0,
                                      transform: "translate3d(16px, 16px, 0px)",
                                    }}
                                  >
                                    <a
                                      href="#"
                                      className="dropdown-item"
                                      onClick={() => openModal(rep)}
                                    >
                                      <i className="icon-reading" />
                                      {rep?.partner?.length < 1
                                        ? "Assign Open Market Vendor"
                                        : "Reassign Open Market Vendor"}
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default OpenMarketPortal;
