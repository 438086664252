import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Loader, Table } from "components";
import AddWarehouseModal from "./AddWarehouseModal";
import DeleteWarehouseModal from "./DeleteWarehouseModal";
import ModalService from "components/modal/ModalService";
import { getWarehouses, allRegions } from "./WarehouseService";
import { useHttp } from "hooks";
import { Link } from "react-router-dom";
import { useWarehouses } from "./hooks/useWarehouses";
import UpdateChannelModal from "./UpdateChannelModal";

const ListWarehouses = () => {
  const { data, gettingWarehouses } = useWarehouses();
  console.log(data?.warehouse);
  const navigate = useNavigate();
  const { serror, loading: eloading, sendHttpRequest: getChannels } = useHttp();
  const [channelData, setChannels] = useState([]);
  const [warehouse, setWarehouse] = useState([]);
  const [loading, setLoading] = useState(false);
  const [regions, setRegions] = useState([]);
  const [dealers, setDealers] = useState([]);
  const {
    error,
    loading: loadingDealers,
    sendHttpRequest: getDealers,
  } = useHttp();
  // Fetch all channels when modal is opened
  useEffect(() => {
    getAllChannels();
  }, []);

  const getAllChannels = () => {
    getChannels("get_channel", "GET", null, ({ data: { channels } }) =>
      setChannels(channels)
    );
  };
  const wareHouseData = data?.warehouse?.map((val) => ({
    _id: val._id,
    wname: val?.name?.toUpperCase() || "-",
    ChannelName: val?.channel?.name || "-",
    dealerName: val?.dealer?.name || "-",
    regionName: val?.region?.name || "-",
    sold: val?.sold || "-",
    totalUnits: val?.totalItems || "-",
    rts: val?.rts || "-",
  }));

  const getInven = async () => {
    setLoading(true);
    try {
      const { warehouse, success } = await getWarehouses();
      if (success) {
        let tempVal = [];
        warehouse?.map((val, index) =>
          tempVal?.push({
            _id: val._id,
            wname: val?.name?.toUpperCase() || "-",
            dealerName: val?.dealer?.name || "-",
            regionName: val?.region?.name || "-",
            sold: val?.sold || "-",
            totalUnits: val?.totalItems || "-",
            rts: val?.rts || "-",
          })
        );
        setWarehouse(tempVal);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };
  const openAddWarehousesModal = () => {
    ModalService.open(AddWarehouseModal, {
      refresh: () => getInven(),
      regions: regions,
      dealers: dealers,
      channelData,
    });
  };

  const updateChannelWarehouse = (el) => {
    ModalService.open(UpdateChannelModal, {
      refresh: () => getInven(),
      warehouse: el,
      channelData,
    });
  };
  const openDeleteModal = (el) => {
    ModalService.open(DeleteWarehouseModal, {
      refresh: () => getInven(),
      warehouse: el,
    });
  };
  const getAllRegions = async () => {
    let { regions, success } = await allRegions();
    if (success) {
      setRegions(regions);
    } else {
      setRegions([]);
    }
  };
  useEffect(() => {
    getAllRegions();
    getDealers("get_dealers", "GET", null, ({ data: { dealers } }) => {
      setDealers(dealers);
    });
    getInven();
  }, []);

  const viewWarehouse = (id) => {
    navigate(`/sales/warehouse/${id}`);
  };

  const columns = [
    {
      label: "#",
      name: "",
      options: {
        sort: false,
        filter: false,
        customBodyRender: (value, tableMeta, update) => {
          let rowIndex = Number(tableMeta.rowIndex) + 1;
          return <span>{rowIndex}</span>;
        },
      },
    },
    {
      name: "_id",
      options: {
        filter: false,
        sort: false,
        display: false,
        print: false,
        download: false,
      },
    },
    {
      label: "Channel",
      name: "ChannelName",
      options: {
        sort: true,
        filter: true,
        search: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "left" },
        }),
      },
    },
    {
      label: "Warehouse",
      name: "wname",
      options: {
        sort: true,
        filter: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre" },
        }),
        customBodyRender: (value, tableMeta, update) => {
          return (
            <div>
              <Link
                style={{ fontWeight: 600 }}
                to={`/sales/warehouse/${tableMeta.rowData[1]}`}
                className="letter-icon-title"
              >
                <p>{value}</p>
              </Link>
            </div>
          );
        },
      },
    },
    {
      label: "Region",
      name: "regionName",
      options: {
        sort: true,
        filter: true,
        search: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "left" },
        }),
      },
    },
    {
      label: "Total Units",
      name: "totalUnits",
      options: {
        sort: true,
        filter: true,
        search: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "left" },
        }),
      },
    },
    {
      label: "RTS",
      name: "rts",
      options: {
        sort: true,
        filter: true,
        search: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "left" },
        }),
      },
    },
    {
      label: "Sold",
      name: "sold",
      options: {
        sort: true,
        filter: true,
        search: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "left" },
        }),
      },
    },
    {
      label: "ACTION",
      name: "_id",
      options: {
        filter: false,
        download: false,
        print: false,
        sort: false,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "left" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div className="list-icons">
              <div className="list-icons-item dropdown">
                <button
                  className="list-icons-item dropdown-toggle caret-0 btn btn-link"
                  data-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="icon-menu7" />
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                  {/* Update Channel */}
                  <button
                    className="dropdown-item"
                    onClick={() => updateChannelWarehouse(value)}
                  >
                    <i className="icon-pencil" /> Edit Channel
                  </button>

                  {/* View Warehouse */}
                  <button
                    className="dropdown-item"
                    onClick={() => viewWarehouse(value)}
                  >
                    <i className="icon-file-eye" /> View
                  </button>

                  {/* Delete Warehouse */}
                  <button
                    className="dropdown-item bg-transparent border-0"
                    onClick={() => openDeleteModal(value)}
                  >
                    <i className="text-danger icon-trash-alt" /> Delete
                  </button>
                </div>
              </div>
            </div>
          );
        },
      },
    },
  ];
  return (
    <>
      {gettingWarehouses && <Loader />}
      {!gettingWarehouses && (
        <>
          <div className="card-body d-md-flex align-items-md-center justify-content-md-between flex-md-wrap">
            <div className="d-flex align-items-center mb-3 mb-md-0"></div>
            <div className="d-flex align-items-center mb-3 mb-md-0">
              <button
                className="dt-button buttons-pdf buttons-html5 btn bg-primary legitRipple "
                tabIndex={0}
                aria-controls="DataTables_Table_0"
                type="button"
                onClick={() => openAddWarehousesModal()}
              >
                <i className="icon-add mr-2" />
                <span>Add Warehouses</span>
              </button>
            </div>{" "}
          </div>
          <Table title={"Warehouses"} data={wareHouseData} columns={columns} />
        </>
      )}
    </>
  );
};

export default ListWarehouses;
