import React from "react";
import Modal from "components/modal/Modal";
import { useDeleteDevice } from "./hooks/useDeleteDevice";
import { useToastContext, ADD } from "context/ToastContext";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

const RMSchema = Yup.object().shape({
  password: Yup.string().required("Password is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
});

const DeleteTotalDevicesModal = (props) => {
  const { toastDispatch } = useToastContext();
  const { deletingRegiteredDevice, isLoading } = useDeleteDevice();
  let loggedInUser = JSON.parse(sessionStorage.getItem("user"));

  const deleteWarrentyDev = (values) => {
    deletingRegiteredDevice(
      {
        password: values.password,
        email: values.email,
        deviceId: props?.id,
        userId: loggedInUser?.id,
      },
      {
        onSuccess: ({ success, msg }) => {
          toastDispatch({
            type: ADD,
            payload: {
              content: msg,
              type: "success",
            },
          });
          props.close();
        },
      }
    );
  };
  return (
    <Modal
      close={props.close}
      title="Authorize By Tech Leader (Delete Registered Device)"
      form="total_devices"
      size="sm"
      loading={isLoading}
    >
      <div className="form-group">
        Are you sure you want to delete the device
        <strong>
          <span className="text-danger">
            <i> {props?.name} </i>
          </span>
        </strong>
        ? <b>This action is irreversible and cannot be undone.</b>
        <br />
        <Formik
          initialValues={{
            password: "",
            email: "",
          }}
          validationSchema={RMSchema}
          onSubmit={(values) => {
            deleteWarrentyDev(values);
          }}
        >
          {({ errors, touched, isSubmitting, setFieldValue }) => (
            <Form>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group row">
                    <label className="col-lg-3 col-form-label">Email:</label>
                    <div className="col-lg-9">
                      <Field
                        name="email"
                        type="email"
                        placeholder=""
                        required
                        className={
                          "form-control" +
                          (errors.email && touched.email ? " is-invalid" : "")
                        }
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group row">
                    <label className="col-lg-3 col-form-label">Password:</label>
                    <div className="col-lg-9">
                      <Field
                        name="password"
                        type="password"
                        placeholder=""
                        required
                        className={
                          "form-control" +
                          (errors.password && touched.password
                            ? " is-invalid"
                            : "")
                        }
                      />
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12" style={{ marginTop: "28px" }}>
                <div className="text-right">
                  <button type="submit" className="btn btn-primary legitRipple"  loading={isLoading} disabled={isLoading}>
                  {isLoading?'Loading...':'Submit'}  <i className="icon-paperplane ml-2" />
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default DeleteTotalDevicesModal;
