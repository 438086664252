import React, { useState, useEffect } from "react";
import ModalService from "components/modal/ModalService";
import AddCouponModal from "./AddCouponModal";
import DeleteCouponModal from "./DeleteCouponModal";
import EditCouponModal from "./EditCouponModal";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { Loader, Table } from "components";
import { useHttp } from "hooks";

const Coupons = () => {
  const navigate = useNavigate();
  const [coupons, setCoupons] = useState([]);
  const { error, loading, sendHttpRequest: getCoupons } = useHttp();
  const [rowCount, setRowCount] = useState(0);
  const [rowProductsCount, setProductsRowCount] = useState(0);
  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    setRowCount(coupons?.length);
    setProductsRowCount(coupons?.length);
  }, [coupons]);

  useEffect(() => {
    setRowCount(filteredData?.length);
  }, [filteredData]);
  const options = {
    customToolbar: () => (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div
          style={{
            marginBottom: "16px",
            fontSize: "18px",
            marginLeft: "-58px",
          }}
        >
          Total Row Count: <b>{rowCount}</b>
        </div>
      </div>
    ),
    onTableChange: (action, tableState) => {
      if (action === "filterChange" || action === "search") {
        // Update the filtered data when filters change
        setFilteredData(tableState?.displayData?.map((row) => row?.data));
      }
    },
  };
  const getAllCoupons = () => {
    getCoupons("all_coupons", "GET", null, ({ data: { coupon } }) => {
      setCoupons(coupon);
      console.log(coupon);
    });
  };

  const addCourierModal = () => {
    ModalService.open(AddCouponModal, {
      refresh: () => getAllCoupons(),
    });
  };
  const deleteCouponModal = (id, name) => {
    ModalService.open(DeleteCouponModal, {
      refresh: () => getAllCoupons(),
      id,
      name,
    });
  };

  const editCoupon = (id) => {
    ModalService.open(EditCouponModal, {
      refresh: () => getAllCoupons(),
      id,
    });
  };

  const viewCoupon = (id) => {
    navigate(`/sales/coupon/${id}`);
  };

  useEffect(() => {
    getAllCoupons();
    console.log(coupons);
  }, []);
  const columns = [
    {
      name: "_id",
      options: {
        filter: false,
        sort: false,
        display: false,
        print: false,
        download: false,
      },
    },

    {
      label: "NAME",
      name: "name",
      options: {
        sort: true,
        filter: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "start" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return <p style={{ fontWeight: "bolder" }}> {value ? value : "-"}</p>;
        },
      },
    },
    {
      name: "currency",
      options: {
        filter: false,
        sort: false,
        display: false,
        print: false,
        download: false,
      },
    },
    {
      label: "VALUE",
      name: "value",
      options: {
        sort: true,
        filter: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "start" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? tableMeta?.rowData[2] + " " + value : "-";
        },
      },
    },
    {
      label: "DESCRIPTION",
      name: "description",
      options: {
        sort: false,
        filter: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "start" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? <small>{value}</small> : "-";
        },
      },
    },

    // {
    //   label: "Used Times",
    //   name: "usedTime",
    //   options: {
    //     sort: false,
    //     filter: true,
    //     setCellProps: () => ({
    //       style: { whiteSpace: "pre" },
    //     }),
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       return value ? value : "-";
    //     },
    //   },
    // },
    {
      label: "START DATE",
      name: "start_date",
      options: {
        sort: true,
        filter: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "start" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? format(new Date(value), "PP") : "-";
        },
      },
    },
    {
      label: "EXPIRY DATE",
      name: "end_date",
      options: {
        sort: true,
        filter: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "start" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? format(new Date(value), "PP") : "-";
        },
      },
    },
    {
      label: "STATUS",
      name: "active",
      options: {
        sort: true,
        filter: false,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "start" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? (
            <span className="text-success">Active</span>
          ) : (
            <span className="text-warning">Inactive</span>
          );
        },
      },
    },
    {
      label: "DATE CREATED",
      name: "createdAt",
      options: {
        sort: true,
        filter: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "start" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? format(new Date(value), "PP") : "-";
        },
      },
    },
    {
      label: "ACTION",
      name: "date",
      options: {
        filter: false,
        download: false,
        print: false,
        sort: false,
        setCellProps: () => ({
          style: { whiteSpace: "pre" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div className="list-icons">
              <div className="list-icons-item dropdown">
                <button
                  className="list-icons-item dropdown-toggle caret-0 border-0 bg-transparent outline-0"
                  data-toggle="dropdown"
                >
                  <i className="icon-menu7" />
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                  <a
                    href
                    className="dropdown-item"
                    onClick={() => viewCoupon(tableMeta.rowData[0])}
                  >
                    <i className="icon-file-eye" /> View
                  </a>
                  <a
                    href
                    className="dropdown-item"
                    onClick={() => editCoupon(tableMeta.rowData[0])}
                  >
                    <i className="icon-pencil" /> Edit
                  </a>
                  <a
                    href
                    className="dropdown-item"
                    onClick={(e) =>
                      deleteCouponModal(
                        tableMeta.rowData[0],
                        tableMeta.rowData[1]
                      )
                    }
                  >
                    <i className="icon-trash" /> Delete
                  </a>
                </div>
              </div>
            </div>
          );
        },
      },
    },
  ];
  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <>
          <div className="card-body d-md-flex align-items-md-center justify-content-md-between flex-md-wrap">
            <div className="d-flex align-items-center mb-3 mb-md-0">
              {" "}
              <div id="tickets-status" />
              <button className="btn bg-transparent   rounded-round border-2 btn-icon">
                <i class="icomoon icon-table"></i>
              </button>
              <div className="ml-3">
                <h3 className="font-weight-semibold mb-0">
                  {rowProductsCount}
                </h3>
                <span>Total Coupons</span>
              </div>
            </div>
            <div className="d-flex align-items-center mb-3 mb-md-0">
              <button
                className="dt-button buttons-pdf buttons-html5 btn bg-primary legitRipple "
                tabIndex={0}
                aria-controls="DataTables_Table_0"
                type="button"
                onClick={() => addCourierModal()}
              >
                <span>New Coupon</span>
              </button>
            </div>{" "}
          </div>
          <Table
            title={
              "Coupons"
              // <>
              //   <div className="card-body d-md-flex align-items-md-center justify-content-md-between flex-md-wrap">
              //     <div className="d-flex align-items-center mb-3 mb-md-0">
              //       <h4 className="card-title">
              //         <i className="icon-cash3 mr-2" />
              //         Coupons
              //       </h4>
              //     </div>

              //     {/* <div className="d-flex align-items-center mb-3 mb-md-0"></div>
              //   </div>
              //   <div className="card-body d-md-flex align-items-md-center justify-content-md-between flex-md-wrap"> */}

              //     <div className="d-flex align-items-center mb-3 mb-md-0">
              //       <div id="tickets-status" />
              //       <button className="btn bg-transparent border-indigo-400 text-indigo-400 rounded-round border-2 btn-icon">
              //         <i className="icon-stack" />
              //       </button>
              //       <div className="ml-3">
              //         <h5 className="font-weight-semibold mb-0">
              //           {coupons?.length}
              //         </h5>
              //         <span className="badge badge-mark border-success mr-1" />{" "}
              //         <span className="text-muted">Total Coupons</span>
              //       </div>
              //     </div>
              //   </div>
              // </>
            }
            data={coupons}
            columns={columns}
            option={options}
          />
        </>
      )}
    </>
  );
};

export default Coupons;
