import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useNavigate, Link } from "react-router-dom";
import { format } from "date-fns";
import { Loader } from "components";
import styles from "../products/productDetails.module.css";
import ModalService from "components/modal/ModalService";
import CancelOrderModal from "./CancelOrderModal";
import { useHttp } from "hooks";

const TransferDetails = () => {
  const [request, setRequest] = useState(null);
  const [images, setImages] = useState([]);
  const { id } = useParams();
  const {
    error: errorTransfers,
    loading: loadingTransfers,
    sendHttpRequest: getSingleTransfer,
  } = useHttp();

  const navigate = useNavigate();

  //
  useEffect(() => {
    getSingleTransfer(
      `/get_single_transfer/${id}`,
      "GET",
      null,
      ({ data: { transfer, filtered } }) => {
        setRequest({ ...transfer, filtered });
      }
    );
  }, []);

  const openModal = () => {
    ModalService.open(CancelOrderModal, {
      refresh: () =>
        getSingleTransfer(
          `/get_single_transfer/${id}`,
          "GET",
          null,
          ({ data: { transfer, filtered } }) => {
            setRequest({ ...transfer, filtered });
          }
        ),
    });
  };
  return (
    <>
      {loadingTransfers && !request && <Loader />}
      {!loadingTransfers && !request && "An error occurred"}
      {!loadingTransfers && request && (
        <div className="d-flex align-items-start flex-column flex-md-row">
          {console.log("REQUEST", request)}
          <div className="w-100 overflow-auto order-2 order-md-1">
            <div className="card">
              <div>
                {" "}
                <button
                  className="btn btn-sm bg-transparent ml-auto "
                  onClick={() => navigate(-1)}
                >
                  <i className="icon-circle-left2 text-teal-400 mr-2" /> Back
                </button>
              </div>
              <div className="card-header header-elements-md-inline">
                <h5 className="card-title">
                  <strong>Transfer Details</strong>
                </h5>
              </div>
              <div className="nav-tabs-responsive bg-light border-top">
                {/* <ul className="nav nav-tabs nav-tabs-bottom flex-nowrap mb-0">
                  <li className="nav-item">
                    <a
                      href="#course-overview"
                      className="nav-link active"
                      data-toggle="tab"
                    >
                      <i className="icon-menu7 mr-2" /> Overview
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="#course-attendees"
                      className="nav-link"
                      data-toggle="tab"
                    >
                      <i className="icon-people mr-2" /> Attendees
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="#course-schedule"
                      className="nav-link"
                      data-toggle="tab"
                    >
                      <i className="icon-calendar3 mr-2" /> Schedule
                    </a>
                  </li>
                </ul> */}
              </div>
              <div className="tab-content">
                <div className="tab-pane fade show active" id="course-overview">
                  <div className="card-body">
                    <div className="mt-1 ">
                      <div className="row">
                        <div className="col-sm-3">
                          <div className="mb-4">
                            <dl>
                              <dt className="font-size-sm font-weight-bold text-uppercase">
                                Source
                              </dt>
                              <dd>{request.source.name}</dd>

                              <dt className="font-size-sm font-weight-bold text-uppercase">
                                User
                              </dt>
                              <dd>
                                {request.user.fname + " " + request.user.lname}
                              </dd>
                            </dl>
                          </div>
                        </div>
                        <div className="col-sm-3">
                          <div className="mb-4">
                            <dl>
                              <dt className="font-size-sm font-weight-bold text-uppercase">
                                Destination
                              </dt>
                              <dd>{request.destination.name}</dd>
                              <dt className="font-size-sm font-weight-bold text-uppercase">
                                Fulfilled
                              </dt>
                              <dd>
                                {request.fulfilled ? (
                                  <span className="text-success-600">Yes</span>
                                ) : (
                                  <span className="text-warning-600">No</span>
                                )}
                              </dd>
                            </dl>
                          </div>
                        </div>
                        <div className="col-sm-3">
                          <div className="mb-4">
                            <dl>
                              <dt className="font-size-sm font-weight-bold text-uppercase">
                                Created Date
                              </dt>
                              <dd>
                                {" "}
                                {format(
                                  new Date(request.createdAt),
                                  "dd MMM yyy"
                                )}
                              </dd>
                              {request.notes && (
                                <>
                                  <dt className="font-size-sm font-weight-bold text-uppercase">
                                    Notes
                                  </dt>
                                  <dd>{request.notes}</dd>
                                </>
                              )}
                            </dl>
                          </div>
                        </div>
                        <div className="col-sm-3">
                          <div className="mb-4">
                            <dl>
                            {request.deliveryId && (
                                <>   <dt className="font-size-sm font-weight-bold text-uppercase">
                                Delivery
                              </dt>
                              <dd>{request.deliveryId}</dd> </>
                              )}
                              {request.invId && (
                                <>
                                  <dt className="font-size-sm font-weight-bold text-uppercase">
                                  Invoice
                                  </dt>
                                  <dd>{request.invId}</dd>
                                </>
                              )}
                            </dl>
                          </div>
                        </div>{" "}
                        <div className="col-sm-3">
                          <div className="mb-4">
                            {request.stockType && (
                              <>
                                {" "}
                                <dl>
                                  <dt className="font-size-sm font-weight-bold text-uppercase">
                                    Stock Type
                                  </dt>
                                  <dd>{request.stockType}</dd>
                                </dl>{" "}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Device Name</th>
                          <th>Variant</th>
                          <th>Ordered Units</th>
                          <th>Color</th>
                          <th>WSP</th>
                          {/* <th>Status</th>
                          <th>Date</th> */}
                          {/* <th>Action</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {request?.filtered.map(
                          ({ model, color, orderDetails, variant }, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>
                                <a href="#">{model.modelName}</a>
                              </td>
                              <td>{`${variant?.ram + variant?.storageType}/${
                                variant?.storage + variant?.storageType
                              }`}</td>
                              <td>{orderDetails.quantity}</td>
                              <td>{color.name}</td>
                              <td>{orderDetails.wsp}</td>
                              {/* <td>
                                <span className="badge bg-success">Closed</span>
                              </td>
                              <td>Oct 21st, 2016</td>
                              <td></td> */}
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className=" wmin-md-400 px-md-3 border-0 shadow-0 order-1 order-md-2 ">
            <div className="card">
              <div className="card-header bg-transparent header-elements-inline">
                <span className="text-uppercase font-size-sm font-weight-semibold">
                  Transfer Details
                </span>
              </div>
              <table className="table table-borderless table-xs my-2">
                <tbody>
                  <tr>
                    <td>Source:</td>
                    <td className="text-right">
                      <a href="#">{product.details.model.modelName}</a>
                    </td>
                  </tr>
                  <tr>
                    <td>Destination:</td>
                    <td className="text-right">
                      {product.details.variant[0]?.storage +
                        "GB / " +
                        product.details.variant[0]?.ram +
                        "GB"}
                    </td>
                  </tr>

                  <tr>
                    <td>Status:</td>
                    <td className="text-right">
                      <div className="btn-group">
                        <a href="#" className="badge bg-info">
                          {product.details.current_status[0]?.name}
                        </a>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td>Total Units:</td>
                    <td className="text-right text-muted">
                      {format(new Date(product.details.updatedAt), "PP")}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div> */}
        </div>
      )}
    </>
  );
};

export default TransferDetails;
