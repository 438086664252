import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteRegisteredDevices } from "../warrentyService";

export function useDeleteDevice() {
  const queryClient = useQueryClient();
  const {
    mutate: deletingRegiteredDevice,
    isPending: isLoading,
    error,
  } = useMutation({
    mutationFn: deleteRegisteredDevices,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["devices"],
      });
    },
  });

  return { deletingRegiteredDevice, isLoading, error };
}
