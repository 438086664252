import OpenMarket from "./OpenMarketPortal";

const OpenMarketRoutes = [
  {
    path: "/open-market",
    element: <OpenMarket />,
  },
];

export default OpenMarketRoutes;
