export const deleteRegisteredDevices = async (data) => {
  let res = await fetch(
    process.env.REACT_APP_BASE_URL + `delete_warranty/${data?.deviceId}`,
    {
      method: "DELETE",
      body:JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        key: process.env.REACT_APP_API_KEY,
        secret: process.env.REACT_APP_API_SECRET,
        country: JSON.parse(sessionStorage.getItem("user"))?.country,
      },
    }
  );
  return await res.json();
};

export const processWarrentyeDevices = async (id) => {
  let res = await fetch(
    process.env.REACT_APP_BASE_URL + `process_warranty/${id}`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        key: process.env.REACT_APP_API_KEY,
        secret: process.env.REACT_APP_API_SECRET,
        country: JSON.parse(sessionStorage.getItem("user"))?.country,
      },
    }
  );
  return await res.json();
};
